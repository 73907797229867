












import PageTitle from '@/components/common/pageTitle.vue'
import { Vue, Component } from 'vue-property-decorator'
import ComponentIndex from './Component/index.vue'

@Component({
  components: {
    ComponentIndex,
    PageTitle
  }
})
export default class EquipmentConfig extends Vue {}
